import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { Avatar, Button, IconButton, makeStyles } from '@material-ui/core'
import { styles } from './layout.style'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import EditIcon from '@material-ui/icons/Edit'
import { useHistory } from 'react-router'
import LockIcon from '@material-ui/icons/Lock'
import MenuIcon from '@material-ui/icons/Menu'
import { useGlobal } from '@Hooks/useGlobal'
import clsx from 'clsx'
const useStyles = makeStyles(styles)
const Header: React.FC<{ children?: React.ReactNode }> = ({
  children,
  ...props
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { openMenu, onToggleMenu } = useGlobal()
  const { user, logout } = useAuth() as AuthContextType
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const isMenuOpen = Boolean(anchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    const isSuccess = await logout()
    if (isSuccess) window.location.reload()
  }

  const handleClickMenu = (path: string) => {
    handleMenuClose()
    history.push(path)
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      classes={{
        paper: classes.menu_paper,
      }}
    >
      <MenuItem onClick={() => handleClickMenu('/user/profile')}>
        <EditIcon className={classes.menu_icon} />
        <Typography variant="body2" color="textSecondary">
          ข้อมูลส่วนตัว
        </Typography>
      </MenuItem>
      {/* <MenuItem
        onClick={() => handleClickMenu('/user/profile/change-password')}
      >
        <LockIcon className={classes.menu_icon} />
        <Typography variant="body2" color="textSecondary">
          เปลี่ยนรหัสผ่าน
        </Typography>
      </MenuItem> */}
      <MenuItem onClick={handleLogout}>
        <ExitToAppIcon className={classes.menu_icon} />
        <Typography variant="body2" color="textSecondary">
          ออกจากระบบ
        </Typography>
      </MenuItem>
    </Menu>
  )

  return (
    <>
      <AppBar
        position="static"
        className={clsx(classes.app_bar, {
          [classes.app_bar_shift]: openMenu,
        })}
      >
        <Toolbar disableGutters className={classes.toolbar}>
          <IconButton onClick={() => onToggleMenu(!openMenu)}>
            <MenuIcon htmlColor="white" />
          </IconButton>
          <div className={classes.grow} />
          <div>
            <Button
              startIcon={<Avatar src={user?.profile_image} />}
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              className={classes.name_color}
            >
              {user?.first_name + ' ' + user?.last_name}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  )
}

export default Header
