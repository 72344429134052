import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  TextField,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import ForwardIcon from '@material-ui/icons/Forward'
import { CustomTheme } from '../../theme'
import { useGlobal } from '@Hooks/useGlobal'
import { useAuth } from '@Hooks/useAuth'

interface CountDownProps {
  duration: number
  onEnd: () => void
}

const useStyles = makeStyles<CustomTheme, {}, string>((theme) => ({
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '40ch',
      display: 'flex',
    },
    padding: '24px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundColor: '#f5f5f5',
  },
  card: {
    display: 'flex',
    maxWidth: '800px',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  logo: {
    padding: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.custom.app_color,
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  countdown: {
    color: theme.custom.app_color,
    padding: '0 4px',
  },
  margin_y: {
    margin: `${theme.spacing(1)}px 0`,
  },
}))

const CountDown: React.FC<CountDownProps> = ({ duration, onEnd }) => {
  const classes = useStyles()
  const [time, setTime] = useState(duration)

  useEffect(() => {
    if (time <= 0) {
      onEnd()
      return
    }

    const inter = setInterval(() => {
      setTime((prev) => {
        if (prev <= 1) {
          clearInterval(inter)
          onEnd()
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(inter)
  }, [time, onEnd])

  return <span className={classes.countdown}>{time}</span>
}

interface G2FAProps {
  _username: string
  onEnd: () => void
}

interface G2FAForm {
  username: string
  number: string
}

const G2FA: React.FC<G2FAProps> = ({ _username, onEnd }) => {
  const classes = useStyles()
  const { wording } = useGlobal()
  const { verifyG2fa, errors } = useAuth()
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [form, setForm] = useState<G2FAForm>({
    username: _username,
    number: '',
  })
  const handleChange = ({ name, value }: { name: string; value: string }) => {
    setForm((prev) => ({ ...prev, [name]: value.replace(/[^\d]/g, '') }))
  }

  const validateForm = () => {
    let _error: string = ''
    if (!form.number) {
      _error = 'กรุณากรอกรหัสยืนยัน'
    } else if (form.number.length !== 6) {
      _error = 'กรุณากรอกรหัสยืนยันให้ครบ'
    }
    if (_error) {
      setError(_error)
      return false
    }
    return true
  }
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }
    setIsLoading(true)
    const verifyG2faResponse = await verifyG2fa({
      username: form.username,
      number: Number(form.number),
    })
    if (verifyG2faResponse) {
      setError('')
      window.location.reload()
    } else {
      setError(errors.message)
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }
  const handleEnd = () => {
    onEnd()
  }
  return (
    <div className={classes.container}>
      <Box boxShadow={2} className={classes.card}>
        <Box className={classes.logo}>
          <Typography style={{ color: '#fff' }} variant="h5">
            {wording['app_name']}
          </Typography>
        </Box>

        <form className={classes.form} onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="center">
            <Box display="flex" flexDirection="column" width="100%">
              <Typography
                variant="h4"
                align="left"
                gutterBottom
                color="textPrimary"
              >
                ยืนยันตัวตน
              </Typography>
              <Typography variant="body2" gutterBottom color="textPrimary">
                ระบบได้ส่งรหัสยืนยัน 6 หลักไปที่อีเมลของคุณแล้ว
                กรุณาตรวจสอบอีเมลและนำรหัสมากรอกในช่องด้านล่างเพื่อยืนยันตัวตน
              </Typography>
              <TextField
                label="รหัสยืนยัน"
                name="number"
                placeholder="กรอกรหัสยืนยัน 6 หลักของคุณที่นี่"
                onChange={(e) =>
                  handleChange({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                value={form.number}
                error={!!error}
                helperText={error}
                inputProps={{ maxLength: 6, inputMode: 'numeric' }}
                fullWidth
                margin={'normal'}
              />
              <Button
                className={classes.margin_y}
                variant="contained"
                color="primary"
                endIcon={
                  isLoading ? <CircularProgress size={24} /> : <ForwardIcon />
                }
                disabled={form.number.length !== 6 || isLoading}
                type="submit"
              >
                {isLoading ? '' : 'ยืนยัน'}
              </Button>
              <label style={{ color: 'red' }}>{error}</label>
              <Typography
                className={classes.margin_y}
                variant={'body2'}
                align={'center'}
              >
                รหัสจะหมดอายุใน{<CountDown duration={120} onEnd={handleEnd} />}
                วินาที
              </Typography>
            </Box>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default G2FA
